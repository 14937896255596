<div style="display: flex; align-items: center; margin-bottom: 1em">
  <app-back-button *ngIf="mode==='view'"></app-back-button>
  <h2 style="margin: 0">Recorded</h2>
</div>
<table mat-table [dataSource]="datasource" matSort class="mat-elevation-z8" style="width: 100%">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 120px">id</th>
    <td mat-cell *matCellDef="let entry">{{entry.id}}</td>
  </ng-container>
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 120px">Title</th>
    <td mat-cell *matCellDef="let entry; let i = index">{{entry.title}}</td>
  </ng-container>
  <ng-container matColumnDef="text">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Text</th>
    <td mat-cell *matCellDef="let entry; let i = index">{{entry.text}}</td>
  </ng-container>
  <ng-container matColumnDef="downloadUrl">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Recording</th>
    <td mat-cell *matCellDef="let entry">
      <div *ngIf="entry.media && entry.media.downloadUrl">
        <audio controls="" name="media" style="width: 180px" *ngIf="entry.type==='audio'">
          <source [src]="entry.media.downloadUrl" />
        </audio>
        <video controls="" name="media" style="width: 180px" *ngIf="entry.type==='video'">
          <source [src]="entry.media.downloadUrl" />
        </video>
      </div>

      <div *ngIf="entry.media && entry.media.downloadUrl">
        <button
          mat-button
          disabled
          *ngIf="!entry._canDeleteMedia"
          title="Only project admins and recording creator can delete"
        >
          <mat-icon>delete</mat-icon>Delete
        </button>
        <button
          *ngIf="entry._canDeleteMedia"
          mat-button
          (click)="deleteRecording(entry)"
          [disabled]="entry._deleting"
        >
          <mat-icon>delete</mat-icon>Delete
        </button>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="editButton" *ngIf="mode==='admin'">
    <th mat-header-cell *matHeaderCellDef style="width: 50px"></th>
    <td mat-cell *matCellDef="let entry">
      <button mat-icon-button (click)="editEntry(entry)">
        <mat-icon>create</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
