import { Component, Input, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { IProject, IDBDocumentDL, IDBEntryChild } from 'src/types/types';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recording-list',
  templateUrl: './recording-list.component.html',
  styleUrls: ['./recording-list.component.scss'],
})
export class RecordingListComponent implements AfterViewInit {
  locked: { [docId: string]: boolean };
  @Input() set lockedDocs(docs: IDBDocumentDL[]) {
    // only set once value has been retrieved from DB (null otherwise)
    if (docs) {
      this.setLockedDocs(docs);
    }
  }
  @Input() set mediaSummary(mediaSummary: IProject['mediaSummary']) {
    this.loadTableData(mediaSummary);
  }
  displayedColumns: string[] = ['type', 'text', 'media'];
  dataSource = new MatTableDataSource<IDatasourceEl>([]);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private cdr: ChangeDetectorRef, private router: Router) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  setLockedDocs(docs: IDBDocumentDL[]) {
    const locked = {};
    docs.forEach((d) => {
      locked[d.id] = true;
    });
    this.locked = locked;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  goToRecording(entry: IDatasourceEl) {
    const { media, docId } = entry;
    // only allow navigation if locked docs have been loaded,
    // media does not exist, and specific doc not lockec
    if (this.locked && !media && !this.locked[docId]) {
      this.router.navigate(['record', docId]);
    }
  }

  loadTableData(summary: IProject['mediaSummary']): void {
    const data = Object.entries(summary).map(([key, value]) => ({
      ...value,
      docId: key,
    }));
    this.dataSource.data = data
      .filter((d) => (d.title && d.text ? true : false))
      .sort((a, b) => (a.docId > b.docId ? 1 : -1));

    // mat-table doesn't automatically detect changes so trigger manually
    this.cdr.detectChanges();
  }
}

interface IDatasourceEl extends IDBEntryChild {
  docId: string;
}
