<mat-horizontal-stepper #stepper>
  <mat-step>
    <ng-template matStepLabel>Set-up</ng-template>
    <app-recording-guidelines [type]="uploadOptions.type"></app-recording-guidelines>
    <div class="button-group">
      <button mat-stroked-button (click)="stepper.next()">
        <mat-icon>arrow_forward</mat-icon>
        Continue
      </button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Record</ng-template>
    <div class="recording-text">{{ recordingText }}</div>
    <div class="button-group recorder-buttons">
      <button
        mat-stroked-button
        (click)="startRecording()"
        [disabled]="status !== 'ready' || !hasRecordPermissions"
      >
        <mat-icon *ngIf="uploadOptions.type === 'audio'">mic</mat-icon>
        <mat-icon *ngIf="uploadOptions.type === 'video'">videocam</mat-icon>
        Start
      </button>
      <button mat-stroked-button (click)="stopRecording()" [disabled]="status !== 'recording'">
        <mat-icon>stop</mat-icon>
        Stop
      </button>
      <input
        type="file"
        #fileUpload
        accept=".webm"
        (change)="fileLoadEvent($event)"
        style="display: none"
      />
      <button (click)="fileUpload.click()" mat-button style="margin-left: auto">
        Upload From File
      </button>
    </div>
    <div style="position: relative">
      <audio
        id="recorderEl"
        class="video-js vjs-default-skin"
        *ngIf="uploadOptions.type === 'audio'"
        #recorderEl
      ></audio>
      <video
        id="recorderEl"
        class="video-js vjs-default-skin"
        *ngIf="uploadOptions.type === 'video'"
        #recorderEl
      ></video>
      <div class="record-overlay" *ngIf="countdown">
        <span class="countdown-text">{{ countdown }}</span>
      </div>
    </div>
    <div class="error-message" *ngIf="errorMsg" style="margin-top: 1em">
      {{ errorMsg }}
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Review</ng-template>
    <div class="recording-text">{{ recordingText }}</div>
    <div *ngIf="recordedData">
      <audio controls="" autoplay *ngIf="uploadOptions.type === 'audio'">
        <source [src]="recordedDataPreview" [type]="recordedData.type" />
      </audio>
      <video controls="" autoplay *ngIf="uploadOptions.type === 'video'">
        <source [src]="recordedDataPreview" [type]="recordedData.type" />
      </video>
    </div>
    <div class="button-group recorder-buttons">
      <button mat-stroked-button (click)="reset()"><mat-icon>undo</mat-icon>Try Again</button>
      <button mat-stroked-button (click)="stepper.next()">
        <mat-icon>thumb_up</mat-icon>Approve
      </button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Upload</ng-template>
    <div *ngIf="recordedData">
      <h3>Recording</h3>
      <div>{{ recordedData.size | filesize }}</div>
      <div>{{ recordedData.type }}</div>
      <div style="margin-top: 1em">
        <mat-checkbox [(ngModel)]="uploadConsentGranted">
          <span
            >I have signed the
            <a href="assets/consent/en.docx" target="_blank" rel="noreferrer nofollow"
              >Consent and Release Form</a
            ></span
          >
        </mat-checkbox>
      </div>
      <div class="button-group">
        <button
          mat-stroked-button
          (click)="upload()"
          [disabled]="status === 'uploading' || !dataService.user || !uploadConsentGranted"
          [title]="dataService.user ? 'Upload' : 'You must sign and confirm consent to upload'"
        >
          <mat-icon>cloud_upload</mat-icon>
          <span *ngIf="!uploadProgress">Upload to Server</span>
          <div *ngIf="uploadProgress">
            <span *ngIf="uploadProgress !== 100">Uploading: {{ uploadProgress }}%</span>
            <span *ngIf="uploadProgress && uploadProgress === 100">Processing...</span>
          </div>
          <span></span>
        </button>
        <button mat-button (click)="download()"><mat-icon>get_app</mat-icon>Save Locally</button>
      </div>
    </div>
    <div class="error-message" *ngIf="uploadErrMsg" style="margin-top: 1em">
      {{ uploadErrMsg }}
    </div>
    <div *ngIf="!recordedData">No Recording to Upload</div>
  </mat-step>
</mat-horizontal-stepper>
