<h2 style="visibility: hidden">Export</h2>
<table mat-table [dataSource]="datasource" matSort class="mat-elevation-z8" style="width: 100%">
  <!-- Table Column Definitions -->
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 120px">Title</th>
    <td mat-cell *matCellDef="let entry; let i = index">{{entry.title}}</td>
  </ng-container>
  <ng-container matColumnDef="media.uploadedByEmail">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Uploaded By</th>
    <td mat-cell *matCellDef="let entry; let i = index">
      <div *ngIf="entry['media.uploadedByID']">
        <div>{{entry["media.uploadedByEmail"] || 'Anonymous'}}</div>
        <div style="font-size: small; opacity: 0.5">{{entry["media.uploadedByID"]}}</div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="media.updated">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Updated</th>
    <td mat-cell *matCellDef="let entry">
      <div *ngIf="entry['media.updated']">{{entry["media.updated"] | date}}</div>
    </td>
  </ng-container>
  <ng-container matColumnDef="media.downloadUrl">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Recording</th>
    <td mat-cell *matCellDef="let entry">
      <button
        mat-icon-button
        *ngIf="entry['media.downloadUrl']"
        (click)="downloadMedia(entry)"
        [disabled]="activeDownloads[entry.id]"
      >
        <span style="margin-right: 5px">{{entry['media.size'] | filesize}}</span>
        <mat-icon>download</mat-icon>
      </button>
    </td>
  </ng-container>
  <!-- Table Rows -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<div style="text-align: right; margin: 1em 0">
  <mat-menu #exportFormatSelect="matMenu" yPosition="above">
    <button
      mat-menu-item
      (click)="generateMediaZip(exportFormat)"
      *ngFor="let exportFormat of exportFormats"
    >
      {{exportFormat.label}}
    </button>
  </mat-menu>
  <button
    mat-button
    [matMenuTriggerFor]="exportFormatSelect"
    mat-flat-button
    color="primary"
    [disabled]="!_project || mediaIsDownloading"
    style="margin-right: 5px"
  >
    <mat-icon>perm_media</mat-icon>
    Media
  </button>
  <button mat-flat-button color="primary" (click)="exportXLSX()" [disabled]="!_project">
    <mat-icon svgIcon="custom_xlsx"></mat-icon>
    Data
  </button>
</div>
