import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';
import { AppLoginComponent } from '../login/login';

@Component({
  selector: 'app-main-header',
  template: `<mat-toolbar color="primary">
    <h1 style="cursor: pointer; overflow:hidden; text-overflow: ellipsis;" routerLink="/">
      {{ strings.HOME_PAGE_TITLE }}
    </h1>
    <span class="metadata" style="margin-left: 1em">v{{ APP_VERSION }}</span>
    <div style="flex: 1"></div>
    <div *ngIf="authService.user$ | async as user; else elseBlock">
      <button mat-icon-button [matMenuTriggerFor]="profileMenu">
        <mat-icon>account_circle</mat-icon>
        <mat-icon style="margin-left: -10px; font-size: 18px">arrow_drop_down</mat-icon>
      </button>
      <mat-menu #profileMenu="matMenu">
        <p class="metadata" style="padding: 5px">
          Signed in as <br />{{ user.email || 'anonymous' }}
        </p>
        <button mat-menu-item (click)="signOut()">
          <mat-icon>power_settings_new</mat-icon>
          <span>Sign Out</span>
        </button>
      </mat-menu>
    </div>
    <ng-template #elseBlock>
      <button mat-button (click)="showLogin()">
        <mat-icon>lock_open</mat-icon>
        Sign In
      </button>
    </ng-template>
  </mat-toolbar>`,
})
export class MainHeaderComponent {
  APP_VERSION = environment.version;
  strings = environment.strings;

  constructor(public authService: AuthService, private dialog: MatDialog, private router: Router) { }

  showLogin() {
    this.dialog.open(AppLoginComponent, { width: '400px' });
  }
  async signOut() {
    await this.authService.signOut();
    this.router.navigate(['/']);
  }
}
