import { Component, AfterViewInit, OnDestroy, ElementRef, ViewChild, Input } from '@angular/core';
import { VideoJsPlayerOptions, VideoJsPlayer } from 'video.js';
import videojsType from 'video.js';

// declare consts imported in index scripts
// Possible direct import methods described here: https://collab-project.github.io/videojs-record/#/frameworks/angular
declare const WaveSurfer: any;
declare const videojs: typeof videojsType;
declare const applyAudioWorkaround: () => void;
import { timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { DataService } from 'src/services/data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-recorder',
  templateUrl: './recorder.component.html',
  styleUrls: ['./recorder.component.scss'],
})
export class RecorderComponent implements AfterViewInit, OnDestroy {
  constructor(public dataService: DataService, private sanitizer: DomSanitizer) {}
  @Input() uploadOptions: IUploadOptions;
  @Input() recordingText: string;
  @ViewChild('recorderEl') recorderEl: ElementRef;
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  uploadConsentGranted = false;
  hasRecordPermissions = false;
  player: VideoJsPlayer & IVideoJsRecordPlugin;
  playerOptions = PLAYER_DEFAULTS;
  recordedData: IVideoJsRecordPlugin['recordedData'];
  recordedDataPreview: any;
  countdown: number;
  uploadProgress: number;
  status: 'ready' | 'starting' | 'recording' | 'complete' | 'uploading';
  errorMsg: string;
  uploadErrMsg: string;

  ngAfterViewInit() {
    this._checkMediaRecordPermissions(this.uploadOptions.type);
    this._playerInit();
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
      this.player = undefined;
    }
  }
  reset() {
    this.recordedData = undefined;
    this.player.record().reset();
    this.stepper.previous();
    this._playerInit();
    this.status = 'ready';
  }

  upload() {
    this.status = 'uploading';
    this.uploadErrMsg = null;
    const data = this.recordedData;
    const { filename, storagePath } = this.uploadOptions;
    const task = this.dataService.createUploadTask(
      // webm used for both audio and video
      `${filename}.webm`,
      storagePath,
      data
    );
    task.catch((err) => {
      console.error(err);
      this.uploadErrMsg = err.message;
      this.status = 'ready';
    });
    task.percentageChanges().subscribe((p) => {
      this.uploadProgress = Math.round(p);
    });
  }
  download() {
    const { filename, type } = this.uploadOptions;
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.player.recordedData);
    this.player.record().saveAs({ [type]: `${filename}.webm` });
  }

  private _playerInit() {
    applyAudioWorkaround();
    const options = this.playerOptions;
    const { type } = this.uploadOptions;
    // toggle video type plugin if specified
    options.plugins.record.video = type === 'video' ? true : false;
    this.player = videojs(this.recorderEl.nativeElement, options, () => {
      // print version information at startup
      console.log(`Player Initialised
        video.js:${videojs.VERSION}
        videojs-record:${videojs.getPluginVersion('record')}
        videojs-wavesurfer:${videojs.getPluginVersion('wavesurfer')}
        wavesurfer.js:${WaveSurfer.VERSION}`);
    }) as any;
    this._addPlayerListeners();
  }

  async startRecording() {
    // before starting record obtain mic permission and use countdown to
    // buy time for activation and allow user time to prepare
    this.status = 'starting';
    this.player.record().getDevice();
    await this.playCountdown();
    this.player.record().start();
  }
  stopRecording() {
    this.player.record().stop();
    this.player.record().stopDevice();
    this.stepper.next();
  }
  async playCountdown(totalSeconds = 3) {
    return new Promise<void>((resolve, reject) => {
      timer(0, 1000)
        .pipe(takeWhile((v) => v < totalSeconds))
        .subscribe(
          (v) => (this.countdown = totalSeconds - v),
          (err) => reject(err),
          () => {
            this.countdown = undefined;
            resolve();
          }
        );
    });
  }

  async fileLoadEvent(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      this.setRecordedData(target.files[0]);
      this.stepper.next();
    }
  }
  private setRecordedData(data: Blob) {
    this.recordedData = data;
    // TODO - should release any previously held object urls
    const previewUrl = window.URL.createObjectURL(this.recordedData);
    this.recordedDataPreview = this.sanitizer.bypassSecurityTrustUrl(previewUrl);
  }
  /**
   * Check whether access is provided to mic and video (if required)
   * https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices
   */
  private async _checkMediaRecordPermissions(type: IUploadOptions['type']) {
    // TODO - first check what mediaDevices are available to see if request even necessary
    // const devices = await navigator.mediaDevices.enumerateDevices();
    // If devices are listed with labels then permission granted (?)
    try {
      const perms = { audio: true, video: type === 'video' ? true : false };
      const stream = await navigator.mediaDevices.getUserMedia(perms);
      // Permissions granted. Stop automated recordings
      this.hasRecordPermissions = true;
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    } catch (err) {
      this.hasRecordPermissions = false;
      /* handle the error */
      if (err.message === 'Permission denied') {
        this.errorMsg = `Permission has been denied to ${type} recording`;
      } else {
        // TODO - log to sentry with additional info, such as devices and/or handle better
        // const devices = await navigator.mediaDevices.enumerateDevices();
        this.errorMsg = err.message;
        throw new Error(err);
      }
    }
  }

  private _addPlayerListeners() {
    // error handling
    this.player.on('deviceError', () => {
      console.log('device error:', this.player.deviceErrorCode);
      this.status = 'ready';
      this.errorMsg = this.player.deviceErrorCode;
    });
    // user clicked the record button and started recording
    this.player.on('startRecord', () => {
      this.status = 'recording';
    });
    // user completed recording and stream is available
    this.player.on('finishRecord', (e) => {
      console.log('finish record', e);
      this.status = 'complete';
      // the blob object contains the recorded data that
      // can be downloaded by the user, stored on server etc.
      this.setRecordedData(this.player.recordedData);
    });
    this.player.on('ready', () => {
      this.status = 'ready';
    });
    this.player.on('timestamp', () => {
      // trigger actions (e.g. stream elsewhere) during recording
    });
  }
}

const PLAYER_DEFAULTS: VideoJsPlayerOptions = {
  controls: false,
  controlBar: {
    fullscreenToggle: false,
    ['deviceButton' as any]: false,
    ['volumePanel' as any]: false,
    ['recordIndicator' as any]: false,
    ['recordToggle' as any]: false,
    ['playToggle' as any]: false,
  },
  loop: false,
  fluid: false,
  height: 320,
  width: 180,
  plugins: {
    wavesurfer: {
      debug: true,
      backend: 'WebAudio',
      waveColor: 'black',
      cursorWidth: 0,
      interact: false,
      hideScrollbar: true,
      plugins: [
        // enable microphone plugin
        WaveSurfer.microphone.create({
          bufferSize: 4096,
          numberOfInputChannels: 1,
          numberOfOutputChannels: 1,
        }),
      ],
    },
    record: {
      audio: true,
      video: false,
      maxLength: 600,
      debug: true,
      autoMuteDevice: true,
      videoMimeType: 'video/webm;codecs=vp8,opus',
      audioMimeType: 'audio/webm',
    },
  },
};

interface IVideoJsRecordPlugin {
  deviceErrorCode: any;
  recordedData: any;
  currentTimestamp: any;
  allTimestamps: any;
  record: () => {
    isRecording: () => any;
    getRecordType: () => any;
    saveAs: (options: any) => any;
    destroy: () => any;
    reset: () => any;
    stopDevice: () => any;
    getDevice: () => any;
    getDuration: () => any;
    getCurrentTime: () => any;
    exportImage: () => any;
    enumerateDevices: () => any;
    setAudioOutput: (deviceId) => any;
    setAudioInput: (deviceId) => any;
    setVideoInput: (deviceId) => any;
    start: () => any;
    stop: () => any;
    pause: () => any;
    resume: () => any;
  };
}

interface IUploadOptions {
  filename: string;
  storagePath: string;
  type: 'audio' | 'video';
}
