import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/services/data.service';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-record-page',
  templateUrl: './record.page.html',
  styleUrls: ['./record.page.scss'],
})
export class RecordPage implements OnInit {
  constructor(public dataService: DataService, public authService: AuthService) {}

  ngOnInit() {
    if (!this.authService.user) {
      this.authService.signInAnonymously();
    }
  }
}
