import { ENV_DEFAULT, IEnvironment } from './environment.default';

export const environment: IEnvironment = {
  ...ENV_DEFAULT,
  /** Default base path to read from DB, e.g. projects/PLH */
  DEFAULT_PROJECT: 'plh',
  DEFAULT_SUBPROJECT: 'parent_app',
  strings: {
    ...ENV_DEFAULT.strings,
    HOME_PAGE_TITLE: 'PLH Media Recorder',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyAQJDsQq-_L1MTQ6tThendJPCHIxbkk1OE',
    authDomain: 'idems-media-recorder-plh.firebaseapp.com',
    projectId: 'idems-media-recorder-plh',
    storageBucket: 'idems-media-recorder-plh.appspot.com',
    messagingSenderId: '435833651631',
    appId: '1:435833651631:web:42a40032ce0280af8bef5a',
  },
  projectAPIProxy: true,
  functionsEndpoint: 'https://us-central1-idems-media-recorder-plh.cloudfunctions.net',
  home: { banner: '' },
};
