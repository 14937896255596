import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/services/data.service';
import { IDBDocumentDL } from 'src/types/types';

@Component({
  selector: 'app-record-editor-page',
  templateUrl: './editor.page.html',
  styleUrls: ['./editor.page.scss'],
})
export class RecordEditorPage implements OnInit, OnDestroy {
  docId: string;
  isDeleting = false;
  errorMsg: string;
  constructor(public dataService: DataService, route: ActivatedRoute) {
    this.docId = route.snapshot.params.docId;
  }

  async ngOnInit() {
    await this.dataService.lockDocument(this.docId);
  }

  getUploadOptions(doc: IDBDocumentDL) {
    return {
      filename: doc._docId,
      storagePath: `${this.dataService.dbPath}`,
      type: doc.type,
    };
  }
  /**
   * When deleting the user will automatically be redi
   */
  deleteRecording(doc: IDBDocumentDL) {
    this.isDeleting = true;
    this.dataService.deleteStorageFile(doc.media.name);
    // As delete triggers server-side updates which will update
    // display on completion, simply add a timeout to prevent delete button
    // from showing for a few seconds
    setTimeout(() => {
      this.isDeleting = false;
    }, 15000);
  }

  ngOnDestroy() {
    this.dataService.unlockDocument(this.docId);
  }
}
