import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-login',
  template: `
    <!-- Login Form -->
    <div [style.display]="form === 'login' ? 'block' : 'none'">
      <h2 mat-dialog-title>Sign In</h2>
      <mat-dialog-content class="mat-typography">
        <form [formGroup]="loginForm">
          <mat-form-field appearance="outline" style="width:100%">
            <mat-label>Email*</mat-label>
            <mat-icon matPrefix style="margin-right:5px">email</mat-icon>
            <input
              matInput
              type="email"
              formControlName="email"
              name="email"
              autocomplete="email"
            />
          </mat-form-field>
          <mat-form-field appearance="outline" style="width:100%">
            <mat-label>Password*</mat-label>
            <mat-icon matPrefix style="margin-right:5px">password</mat-icon>
            <input
              matInput
              type="password"
              formControlName="password"
              name="current-password"
              autocomplete="current-password"
            />
          </mat-form-field>
        </form>
        <div style="text-align:right; opacity:0.5; margin-top:-1em">
          <button mat-button (click)="resetPassword()">Forgot your password?</button>
        </div>
        <div class="error-message" *ngIf="errorMessage">{{ errorMessage }}</div>
        <div (click)="showSignUpForm()" style="margin-top:1em">
          <span style="margin-right:5px">Don't have an account?</span>
          <span style="font-weight:500; text-decoration:underline; cursor:pointer">Create</span>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions style="justify-content:flex-end">
        <button
          mat-raised-button
          color="primary"
          [disabled]="!loginForm.valid || isProcessing"
          (click)="login()"
        >
          Sign In
        </button>
      </mat-dialog-actions>
    </div>
    <!-- SignUp Form -->
    <div [style.display]="form === 'signup' ? 'block' : 'none'">
      <h2 mat-dialog-title>Create Account</h2>
      <mat-dialog-content class="mat-typography">
        <form [formGroup]="signUpForm">
          <mat-form-field appearance="outline" style="width:100%">
            <mat-label>Email*</mat-label>
            <mat-icon matPrefix style="margin-right:5px">email</mat-icon>
            <input
              matInput
              type="email"
              formControlName="email"
              autocomplete="email"
              name="email"
            />
          </mat-form-field>
          <mat-form-field appearance="outline" style="width:100%">
            <mat-label>Password*</mat-label>
            <mat-icon matPrefix style="margin-right:5px">password</mat-icon>
            <input
              matInput
              type="password"
              formControlName="password"
              autocomplete="current-password"
              name="current-password"
            />
          </mat-form-field>
          <mat-form-field appearance="outline" style="width:100%">
            <mat-label>Confirm Password*</mat-label>
            <mat-icon matPrefix style="margin-right:5px">password</mat-icon>
            <input matInput type="password" formControlName="confirmation" autocomplete="off" />
          </mat-form-field>
          <mat-checkbox formControlName="consent">
            <div>
              I agree to the terms of the
              <a href="assets/static/privacy.html" target="_blank" rel="nofollow noreferrer"
                >Privacy Policy</a
              >
            </div>
          </mat-checkbox>
          <div class="error-message" *ngIf="errorMessage">{{ errorMessage }}</div>
        </form>
      </mat-dialog-content>
      <mat-dialog-actions style="justify-content:flex-end">
        <button
          mat-raised-button
          color="primary"
          [disabled]="
            !signUpForm.valid ||
            signUpForm.value.password !== signUpForm.value.confirmation ||
            isProcessing
          "
          (click)="signUp()"
        >
          Create
        </button>
      </mat-dialog-actions>
    </div>
  `,
})
export class AppLoginComponent {
  form: 'login' | 'signup' = 'login';
  loginForm: FormGroup;
  signUpForm: FormGroup;
  errorMessage: string;
  isProcessing = false;
  constructor(fb: FormBuilder, private authService: AuthService, private dialog: MatDialog) {
    this.loginForm = fb.group({
      email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
      password: new FormControl('', Validators.required),
    });
    this.signUpForm = fb.group({
      email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
      password: new FormControl('', Validators.required),
      confirmation: new FormControl('', Validators.required),
      consent: new FormControl('', Validators.requiredTrue),
    });
  }

  login() {
    this.errorMessage = null;
    this.isProcessing = true;
    const { email, password } = this.loginForm.value;
    this.authService
      .signInWithEmailPassword(email, password)
      .catch((err) => this.handleError(err.message))
      .then((res) => {
        this.isProcessing = false;
        if (res && res.user) {
          this.dialog.closeAll();
        }
      });
  }
  showSignUpForm() {
    this.signUpForm.patchValue(this.loginForm.value);
    this.form = 'signup';
  }
  resetPassword() {
    this.errorMessage = null;
    this.isProcessing = true;
    const { email } = this.loginForm.value;
    if (email) {
      this.authService
        .resetPassword(email)
        .catch((err) => this.handleError(err.message))
        .then(() => {
          this.isProcessing = false;
          this.errorMessage = 'A password reset link has been emailed to you';
        });
    } else {
      this.errorMessage = 'Specify email address to send password reset link';
    }
  }
  signUp() {
    this.errorMessage = null;
    this.isProcessing = true;
    const { email, password } = this.signUpForm.value;
    this.authService
      .signUpWithEmailPassword(email, password)
      .catch((err) => {
        console.error(err);
        this.handleError(err.message);
      })
      .then((res) => {
        this.isProcessing = false;
        console.log('signup res', res);
        if (res && res.user) {
          this.dialog.closeAll();
        }
      });
  }
  handleError(message: string) {
    this.isProcessing = false;
    this.errorMessage = message;
  }
}
