import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first, take } from 'rxjs/operators';
import { DataService } from 'src/services/data.service';
import { IMediaDocument } from 'src/types/types';

@Component({
  selector: 'app-project-entry-editor',
  templateUrl: './project-entry-editor.html',
  styleUrls: ['./project-entry-editor.scss'],
})
export class ProjectEntryEditorComponent implements OnInit {
  entryForm: FormGroup;
  isSaving = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { entry: IMediaDocument },
    private dialog: MatDialog,
    private fb: FormBuilder,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.initialiseProjectForm();
  }

  initialiseProjectForm() {
    // Custom template for hackathon forms
    const { id, meta, text, type, media, title } = this.data.entry;
    const form: { [key in keyof IMediaDocument]: FormControl } = {
      id: new FormControl({ value: id, disabled: true }, [Validators.required]),
      meta: new FormControl(meta),
      text: new FormControl(text, [Validators.required]),
      type: new FormControl(type, [Validators.required]),
      media: new FormControl(media || null),
      title: new FormControl(title || null, [Validators.required]),
    };
    this.entryForm = this.fb.group(form);
    console.log('form', this.entryForm.getRawValue());
  }

  async save() {
    this.isSaving = true;
    const doc = this.entryForm.getRawValue();
    this.entryForm.disable();
    // backend functions will handle update if uploading a doc that already exists
    await this.dataService.uploadDBDocuments([doc]);
    // wait for confirmation that the active project has been updated.
    // expect 2 updates - initial behaviour subject value and then updated value once backend functions
    // have populated media summary update
    await this.dataService.activeProject$.pipe(take(2)).toPromise();
    this.isSaving = false;
    this.entryForm.enable();
    this.dialog.closeAll();
  }
  delete() {}
}
