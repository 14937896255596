import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { hasFlag } from 'country-flag-icons';
import { LANGUAGES_LIST } from 'src/data/lists/countries';
import { IProject } from 'src/types/types';

@Component({
  selector: 'app-project-card',
  template: `<mat-card class="project-card" [ngClass]="containerClass" data-cy="project-card">
    <div style="display:flex">
      <div style="flex:1">
        <h2 style="flex:1; margin:0" data-cy="project-label">{{ project.label }}</h2>
        <h4 class="subtitle" data-cy="project-subproject">
          {{ project.subproject }}
        </h4>
      </div>
      <div style="margin-bottom:1em; margin-left:1em; text-align:right" F>
        <img *ngIf="flagSrc" [src]="flagSrc" style="width:30px" />
        <div *ngIf="!flagSrc">{{ project.country }}</div>
        <div *ngIf="langLocalName">{{ langLocalName }}</div>
        <div *ngIf="!langLocalName">{{ project.language }}</div>
      </div>
      <!-- <mat-card-subtitle>{{project.description}}</mat-card-subtitle> -->
    </div>
    <mat-card-footer style="padding: 0 16px; margin-top:auto">
      <app-project-progress [project]="project"></app-project-progress>
    </mat-card-footer>
  </mat-card>`,
  styles: [
    `
      .project-card.no-hover:hover {
        background: white;
        /* cursor:unset; */
      }
      .project-card:hover {
        /*  TODO - link with material accent colour */
        background: #fff7c0;
      }
      .project-card {
        cursor: pointer;
      }
      mat-card-subtitle {
        min-height: 1.5em;
      }
      :host {
        display: flex;
        flex-direction: column;
      }
      mat-card {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    `,
  ],
})
export class ProjectCardComponent implements OnInit {
  @Input() project: IProject;
  @Input() containerClass?: 'no-hover';
  flagSrc: SafeUrl;
  langLocalName: string;
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    const { country, language } = this.project;
    if (country && hasFlag(this.project.country)) {
      const flagSrc = `https://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`;
      this.flagSrc = this.sanitizer.bypassSecurityTrustUrl(flagSrc);
    }
    if (language) {
      const lang = this.getFullLangCodes(language);
      if (lang) {
        this.langLocalName = lang.local;
      }
    }
  }
  private getFullLangCodes(iso3code: string) {
    return LANGUAGES_LIST.find((l) => l['3'] === iso3code);
  }
}
