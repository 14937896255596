import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// AngularFire
import { AngularFireModule } from '@angular/fire';
import {
  AngularFirestoreModule,
  USE_EMULATOR as USE_FIRESTORE_EMULATOR,
} from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import {} from '@angular/fire/storage/';
import { AngularFireAuthModule, USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/auth';
import {
  AngularFireFunctionsModule,
  USE_EMULATOR as USE_FUNCTIONS_EMULATOR,
} from '@angular/fire/functions';

import * as Sentry from '@sentry/angular';

import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MaterialComponentsModule } from './components/material.components';
import { FormsModule } from '@angular/forms';

import { AppComponentsModule } from './components/app-components.module';
import { AppPagesModule } from './pages/pages.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    MaterialComponentsModule,
    BrowserAnimationsModule,
    AppComponentsModule,
    AppPagesModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    // firebase emulators
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.firebaseConfig._useEmulators ? ['localhost', 4004] : undefined,
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.firebaseConfig._useEmulators ? ['localhost', 4002] : undefined,
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.firebaseConfig._useEmulators ? ['localhost', 4001] : undefined,
    },
    // Not currently supported (available in v7 of angular fire)
    // {
    //   provide: USE_STORAGE_EMULATOR,
    //   useValue: environment.firebaseConfig._useEmulators ? ['localhost', 4003] : undefined,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
