<div class="page">
  <h2>Import Strings</h2>
  <ngx-file-drop
    *ngIf="!fileEntry && (dataService.activeProject$ | async)"
    dropZoneLabel="Drop files here"
    (onFileDrop)="fileDropped($event)"
    accept=".json"
    multiple="false"
  >
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      Drop file here or
      <button mat-button type="button" (click)="openFileSelector()">browse</button>
    </ng-template>
  </ngx-file-drop>
  <div *ngIf="!fileEntry">
    <h3 style="margin-top: 1em">JSON Specification</h3>
    <table style="width: 100%">
      <tr>
        <th>field</th>
        <th>description</th>
      </tr>
      <tr>
        <td>text</td>
        <td>text to display for recording</td>
      </tr>
      <tr>
        <td>type</td>
        <td>audio or video</td>
      </tr>
      <tr>
        <td>title</td>
        <td>
          (optional) - this will be displayed on recording select screen. If omitted the recording
          text will be displayed
        </td>
      </tr>
      <tr>
        <td>id</td>
        <td>(optional) - specify an id, or by default use one generated from the text</td>
      </tr>
      <tr>
        <td>...</td>
        <td>any other fields provided will be appended as metadata</td>
      </tr>
      <!-- <tr>
        <td>comment</td>
        <td>(optional) - will be displayed separate to the text</td>
      </tr> -->
    </table>
    <p style="margin-top: 1em">
      Here is a
      <a
        href="assets/sample-import-data.json"
        download
        target="_blank"
        rel="noopener noreferrer nofollow"
        >sample json file</a
      >, used to populate the test project
    </p>
  </div>

  <mat-card *ngIf="fileEntry">
    <mat-card-content *ngIf="summary">
      <mat-card-subtitle>Summary</mat-card-subtitle>
      <div>Import: {{summary.import.length}}</div>
      <div>Ignore: {{summary.ignore.length}}</div>
      <mat-checkbox [(ngModel)]="selectedWipeProjectData" style="margin-top: 1em">
        Clear existing data
      </mat-checkbox>
    </mat-card-content>
    <div *ngIf="errorMsg">{{errorMsg}}</div>
    <mat-card-actions>
      <button mat-button (click)="cancel()"><mat-icon>close</mat-icon>Cancel</button>
      <button mat-button (click)="import()" *ngIf="summary" [disabled]="isImporting">
        <mat-icon>input</mat-icon>Import
      </button>
    </mat-card-actions>
  </mat-card>
</div>
