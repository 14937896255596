import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { utils, writeFile } from 'xlsx';
import { flatten, unflatten } from 'flat';
import { saveAs } from 'file-saver';
import { IMediaSummaryExported, IProject } from 'src/types/types';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FunctionsService } from 'src/services/functions.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';

interface IExportFormat {
  label: string;
  audio_format?: string;
  video_format?: string;
}

@Component({
  selector: 'app-project-export',
  templateUrl: './project-export.html',
  styleUrls: ['./project-export.scss'],
})
export class ProjectExportComponent implements AfterViewInit {
  constructor(private functionsService: FunctionsService, private authService: AuthService) {}

  displayedColumns = ['title', 'media.uploadedByEmail', 'media.updated', 'media.downloadUrl'];
  datasource = new MatTableDataSource<IDatasourceEl>([]);
  activeDownloads: { [media_id: string]: boolean } = {};
  mediaIsDownloading = null;
  exportFormats: IExportFormat[] = [
    { label: 'Original' },
    { label: '240p', video_format: '240p', audio_format: 'mp3' },
    { label: '360p', video_format: '360p', audio_format: 'mp3' },
    { label: 'mp3', video_format: 'mp3', audio_format: 'mp3' },
  ];
  _project: IProject;

  @Input() set project(project: IProject) {
    this._project = project;
    if (project) {
      const exportData: IDatasourceEl[] = [];
      const { mediaSummary } = project;
      Object.entries(mediaSummary).forEach(([id, summary]) => {
        const { title, type, media, text } = summary;
        let dataEl: IDatasourceEl = { id, text, type, title };
        if (media) {
          Object.entries(flatten({ media })).forEach(([key, value]) => {
            dataEl[key] = value;
          });
        }
        exportData.push(dataEl);
      });
      this.datasource = new MatTableDataSource(exportData);
      utils.json_to_sheet(this.datasource.data);
    }
  }

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.datasource.sort = this.sort;
  }

  /** Convert json data to xlsx and force download */
  exportXLSX() {
    const worksheet = utils.json_to_sheet(this.datasource.data);
    const workbook = utils.book_new();
    const sheetName = this._project.label;
    const timestamp = new Date().toISOString().substring(0, 10).replace(/-/g, '');
    const workbookName = `recordings-${this._project.deployment}-${timestamp}`;
    utils.book_append_sheet(workbook, worksheet, sheetName);
    writeFile(workbook, `${workbookName}.xlsx`);
  }

  async exportSingle(format: IExportFormat) {}

  async generateMediaZip(format: IExportFormat) {
    console.log('generating media export', this._project);
    const { deployment, project, subproject, language } = this._project;
    // If using a proxy all requests can just be sent to origin (proxied as /project-api)
    const baseEndpoint = environment.projectAPIProxy
      ? location.origin
      : environment.functionsEndpoint;
    const apiEndpoint = `project-api/${project}/${subproject}/${deployment}/${language}/strings/export`;
    const url = new URL(`${baseEndpoint}/${apiEndpoint}`);
    const { audio_format, video_format, label } = format;
    if (audio_format && video_format) {
      url.search = `video_format=${video_format}&audio_format=${audio_format}`;
    }
    const downloadUrl = url.toString();
    const filename = `${deployment}-${label}.zip`;
    this.mediaIsDownloading = true;
    // Generate an auth token to send with the request
    const authToken = await this.authService.user.getIdToken();

    const xhr = new XMLHttpRequest();

    xhr.withCredentials = false;
    xhr.responseType = 'blob';
    xhr.onload = async () => {
      // ensure correct response
      if (xhr.status === 200 && xhr.responseType === 'blob') {
        const blob = xhr.response;
        saveAs(blob, filename);
      } else {
        switch (xhr.responseType) {
          case 'blob':
            const blob = xhr.response as Blob;
            const err = await blob.text();
            console.error(err);
            break;
          default:
            console.error(xhr.response);
        }
      }
      this.mediaIsDownloading = false;
    };
    xhr.open('GET', downloadUrl, true);
    xhr.timeout = 540 * 1000; // set max timeout at 540s (same as target firebase function)
    xhr.setRequestHeader('Authorization', `Bearer ${authToken}`);
    xhr.onerror = (e) => {
      saveAs(downloadUrl, filename);
      this.mediaIsDownloading = false;
    };
    xhr.send();
  }

  /**
   * Force download of media resource as per
   * https://firebase.google.com/docs/storage/web/download-files#web-version-9_1
   * NOTE - requires cors config (can be set via `yarn workspace scripts configure`)
   **/
  downloadMedia(entry: IDatasourceEl) {
    // re-nest media fields
    entry = unflatten(entry);
    const { id } = entry;
    this.activeDownloads[id] = true;
    const { downloadUrl, name } = entry.media as IMediaSummaryExported;
    const filename = name.split('/').pop();
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const blob = xhr.response;
      saveAs(blob, filename);
      this.activeDownloads[id] = false;
    };
    xhr.open('GET', downloadUrl);
    xhr.onerror = (e) => {
      // fallback to browser method (in case of cors issues)
      // NOTE - uses HEAD request which not currently supported, so opens in tab
      // https://github.com/firebase/firebase-js-sdk/issues/2623
      saveAs(downloadUrl, filename);
      this.activeDownloads[id] = false;
    };
    xhr.send();
  }
}

// The datasource is the same as project media summary entries, with additional metadata field
type IDatasourceEl = IProject['mediaSummary'][0] & {
  id: string;
  // Additional fields may come from flattened media summary, but not strongly typed here
  [key: string]: any;
};
