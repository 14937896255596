<div class="page">
  <div style="display: flex; align-items: center; margin-bottom: 1em">
    <app-back-button></app-back-button>
    <h2 style="margin: 0">{{dataService.activeProject.label}}</h2>
    <div style="opacity: 0.3; font-size: small; margin-left: auto">
      {{dataService.activeProject.deployment}}
    </div>
  </div>
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label><mat-icon>translate</mat-icon>Content</ng-template>
      <div style="margin-bottom: 2em">
        <app-project-summary mode="admin"></app-project-summary>
      </div>
      <app-auth-check allow="super-admin">
        <button mat-stroked-button (click)="promptLoadTemplate()" [disabled]="isLoading">
          Load Template
        </button>
        <button
          mat-stroked-button
          [disabled]="isLoading"
          style="margin-left: 10px"
          routerLink="/import"
        >
          Load JSON
        </button>
      </app-auth-check>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label><mat-icon>people</mat-icon>Users</ng-template>
      <h3 style="margin-top: 1em">Admins</h3>
      <mat-list>
        <mat-list-item *ngFor="let admin of dataService.activeProject.admins; last as last">
          <div matLine>{{admin}}</div>
          <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
        </mat-list-item>
      </mat-list>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label><mat-icon>settings</mat-icon>Settings</ng-template>
      <app-project-editor [project]="dataService.activeProject"></app-project-editor>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label><mat-icon>save_alt</mat-icon>Export</ng-template>
      <app-project-export [project]="dataService.activeProject"></app-project-export>
    </mat-tab>
  </mat-tab-group>
</div>
