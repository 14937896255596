import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize',
})
/**
 * Take a byte value and return as KB/MB text
 */
export class FilesizePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return formatBytes(value, 1);
  }
}

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
