export default [
  {
    text: '',
    id: '01 Quality Time – Babies',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '02 Quality Time - Children',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '03 Quality Time - Teens',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '04 Staying Positive',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '05 Giving positive instructions',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '06 Positive praise for good behaviour',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '07 Create a routine',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '08 Draw up household rules',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '09 Encouraging good behaviour: changing your approach',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '10 Encouraging good behaviour: teaching consequences',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '11 Reading together',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '12 Encouraging good behaviour: not reacting to tantrums',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '13 Encouraging good behaviour: solving problems',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '14 Keeping children safe online',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '15 Soothing a crying baby',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '16 Helping children manage their emotions',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '17 Helping your child through a difficult situation',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '18 Protecting your child against abuse',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '19 Family Harmony at home',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '20 Getting family finances right',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '21 Looking after yourself',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '22 Take a break',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '23 Finding calm',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '24 The magic of 3',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '25 Anger management advice',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '26 Keeping your cool',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '27 Get active, be happy',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '28 Staying safe online',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '29 Talking about difficult topics',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '30 Caring for a child with disabilities',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '31 An introduction to child development',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '32 Child Development: Babies & toddlers',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '33 Child Development: Children aged 2 - 9 years',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '34 Child Development: Teenagers aged 10 - 17 years',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '35 Support your child’s education',
    title: '',
    type: 'video',
  },
  {
    text: '',
    id: '36 Partner Relationships - IPV',
    title: '',
    type: 'video',
  },
];
