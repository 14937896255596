import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IProject, IProjectMeta, IUser } from 'src/types/types';
import { AuthService } from 'src/services/auth.service';
import { DataService } from 'src/services/data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-auth-check',
  styleUrls: [],
  template: ` <ng-content *ngIf="canAccess"></ng-content> `,
})
/**
 * Uses active user and project to apply restriction on child content depending on levels:
 * admin - only people with admin access to the current project can access
 * owner - only people with admin access or identified as owner
 * authenticated - only people authenticated (non including anonymous)
 * anonymous - only people authenticated (including anonymous)
 * all - allow everyone
 *
 * NOTE 1 - all validation is done at the project level, using emails as user identifiers
 * NOTE 2 - inverse option provided for cases where two sets of auth-checks used together,
 * one for the allowed user and one for everyone else
 */
export class AuthCheckComponent implements OnInit, OnDestroy {
  @Input() allow: 'admin' | 'owner' | 'authenticated' | 'anonymous' | 'all' | 'super-admin';
  @Input() ownerEmail: string;
  @Input() inverse: boolean;
  canAccess = false;
  private user: IUser;
  private project: IProject;
  private projectMeta: IProjectMeta;
  private destroyed$ = new Subject<boolean>();
  constructor(private authService: AuthService, private dataService: DataService) { }

  ngOnInit() {
    this._subscribeToUserAndProjectUpdates();
  }
  ngOnDestroy() {
    this.destroyed$.next(true);
  }

  private checkPermission(): boolean {
    const project = this.project || ({ admins: [] } as IProject);
    const user = this.user || ({ email: '' } as IUser);
    const superAdmins = this.projectMeta?.['admins'] || [];
    switch (this.allow) {
      // TODO - better organise to project/subproject admin levels
      case 'super-admin':
        return user.email && superAdmins.includes(user.email);
      case 'admin':
        return this._isAdmin(user, project);
      case 'owner':
        return this._isAdmin(user, project) || this.user.email === this.ownerEmail;
      case 'authenticated':
        return this.user && this.user.isAnonymous === false;
      case 'anonymous':
        return this.user ? true : false;
      case 'all':
        return true;
      default:
        return false;
    }
  }

  /**
   * Set the canAccess variable, toggling the value if inverse selected
   */
  private setPermission(canAccess: boolean) {
    this.canAccess = this.inverse ? !canAccess : canAccess;
  }

  private _isAdmin(user: IUser, project: IProject) {
    return project.admins && project.admins.includes(user.email) ? true : false;
  }

  /**
   * Watch for changes to project and user and update permissions accordingly
   */
  private _subscribeToUserAndProjectUpdates() {
    this.authService.user$.pipe(takeUntil(this.destroyed$)).subscribe((u) => {
      this.user = u;
      this.setPermission(this.checkPermission());
    });
    this.dataService.activeProject$.pipe(takeUntil(this.destroyed$)).subscribe((p) => {
      this.project = p;
      this.setPermission(this.checkPermission());
    });
    this.dataService.projectMeta$.pipe(takeUntil(this.destroyed$)).subscribe((p) => {
      this.projectMeta = p;
      this.setPermission(this.checkPermission());
    });
  }
}
