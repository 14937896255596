import { countries as DEFAULT_COUNTRIES } from 'countries-list';
import langs from 'langs';

// https://en.wikipedia.org/wiki/ISO_639-3
// https://en.wikipedia.org/wiki/ISO_639:c

export const COUNTRIES_LIST = applyCountryOverrides();
export const LANGUAGES_LIST = applyLanguageOverrides();

/** Hardcoded overrides to npm countries-list defaults */
function applyCountryOverrides(countries = DEFAULT_COUNTRIES) {
  // add tagalog family of languages to phillipines
  countries.PH.languages.push('tl');
  return countries;
}

function applyLanguageOverrides(): ILang[] {
  const DEFAULT_LANGS = langs.all() as ILang[];
  // remove specific langs by ISO 639-3 code (tagalog to replace with filipino instead)
  const filteredLangs = DEFAULT_LANGS.filter((l) => !['tgl'].includes(l['3']));

  // https://github.com/adlawson/nodejs-langs/issues/13
  const MISSING_LANGUAGES: ILang[] = [
    { local: '官話; 北方話', name: 'Mandarin Chinese', 1: 'zh', 3: 'cmn' },
    { local: '廣東話', name: 'Yue Chinese (Cantonese)', 1: 'zh', 3: 'yue' },
    // custom assign filipino variant of tagalog lang
    { local: 'Wikang Filipino', name: 'Filipino', 1: 'tl', 3: 'fil' },
  ];
  return [...MISSING_LANGUAGES, ...filteredLangs];
}

/**
 * Type definitions as adapted from:
 * https://github.com/adlawson/nodejs-langs
 */
interface ILang {
  name: string;
  local: string;
  '1': string; // ISO 639-1
  '2'?: string; // ISO 639-2
  '2T'?: string; // ISO 639-2T
  '2B'?: string; // ISO 639-2B
  '3': string; // ISO 639-3
}

/**
 *
 * https://www.npmjs.com/package/countries-list
 * https://www.npmjs.com/package/langs
 * https://en.wikipedia.org/wiki/ISO_639_macrolanguage
 * https://en.wikipedia.org/wiki/ISO_3166-1
 */
