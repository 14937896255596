import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-back-button',
  template: `
  <button  mat-icon-button (click)="back()"><mat-icon>arrow_back</mat-icon>{{buttonText}}</button>
  `,
  styles: [
    `
      :host {
        float: left;
      }
    `,
  ],
})
export class BackButtonComponent {
  @Input() buttonText = ""
  constructor(private location: Location) { }

  back() {
    this.location.back();
  }
}
