<div *ngIf="type === 'audio'">
  <h3>Audio Recording Guidelines</h3>
  <h4>Technical set-up</h4>
  <ul>
    <li>Find a quiet spot to do the recording</li>
    <li>Use a headphone microphone, so you don’t have to hold the phone close to your mouth</li>
  </ul>
  <h4>Prepare for recording</h4>
  <ul>
    <li>Stretch and stretch your face before starting the recording, so you look relaxed</li>
  </ul>
  <h4>Guidance for recording</h4>
  <ul>
    <li>Read at a calm pace and articulate well – try to smile when you talk</li>
    <li>Speak as if you are addressing someone directly, so your audience feels connected!</li>
    <li>
      Emphasise the right words by feeling and showing the words you are saying (e.g. take a breath,
      etc.).
    </li>
    <li>
      Look for opportunities to make the recording dynamic (e.g. keep your voice interesting, make
      hand gestures, do the activities, etc.)
    </li>
    <li>Keep active till the end – people’s attention span disappears after 1 minute!</li>
  </ul>
</div>
<div *ngIf="type === 'video'">
  <h3>Video Recording Guidelines</h3>
  <div style="display: flex; margin-top: -1em; margin-bottom: 1em">
    <a
      href="assets/static/recording-guidelines/guidelines.deu.pdf"
      target="_blank"
      rel="nofollow noreferrer"
      style="margin-right: 10px"
      >Deutsch</a
    >
    <a
      href="assets/static/recording-guidelines/guidelines.spa.pdf"
      target="_blank"
      rel="nofollow noreferrer"
      style="margin-right: 10px"
      >Español</a
    >
    <a
      href="assets/static/recording-guidelines/guidelines.srp.pdf"
      target="_blank"
      rel="nofollow noreferrer"
      style="margin-right: 10px"
      >српски језик</a
    >
  </div>

  <h4>Technical set-up</h4>
  <ul>
    <li>
      Find a quiet spot to do the recording and have a calm background (no people walking behind
      you)
    </li>
    <li>Ensure sufficient lighting</li>
    <li>
      Camera positioning: Have the camera at eye level and let the camera show your whole face and
      part of your upper body.
    </li>
    <li>o Preferably use a laptop instead of phone for recording.</li>
    <li>
      If doing audio-only, use a headphone microphone so you don’t have to hold the phone close to
      your mouth.
    </li>
    <li>If you do use a phone: Record in landscape mode and look at camera dot – not yourself!)</li>
  </ul>
  <h4>Prepare for recording</h4>
  <ul>
    <li>Stretch and stretch your face before starting the recording, so you look relaxed</li>
    <li>
      Put the script directly below the camera so that you are always looking at the camera when
      reading the script (try to show the whole script so you don’t need to scroll)
    </li>
  </ul>
  <h4>Guidance for recording</h4>
  <ul>
    <li>Read at a calm pace and articulate well – try to smile when you talk</li>
    <li>
      Look at the camera dot as much as you can as if talking to a person directly, so your audience
      feels connected to you!
    </li>
    <li>
      Emphasise the right words by feeling and showing the words you are saying (e.g. take a breath,
      etc.).
    </li>
    <li>
      Look for opportunities to make the recording dynamic (e.g. keep your voice interesting, make
      hand gestures, do the activities, etc.)
    </li>
    <li>Keep active till the end – people’s attention span disappears after 1 minute!</li>
  </ul>
</div>
