import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({ providedIn: 'root' })

/** Methods to communicate with cloud functions **/
export class FunctionsService {
  constructor(private fns: AngularFireFunctions) {
    if (location.hostname === 'localhost') {
      this.serveFunctionsLocally();
    }
  }

  /**
   * Wrapper around firebase callable function
   *
   * NOTE CC 2021-11-05
   * Not currently in use as only can return JSON and not files as
   * required for projectDeployment export. Retain for future use
   */
  public callFunction(name: ICallableFunctionName, payload = {}) {
    const mergedPayload = { ...FUNCTION_DEFAULTS[name], ...payload };
    const callable = this.fns.httpsCallable(name);
    return callable(mergedPayload);
  }

  /** Run functions from localhost (see notes in readme for use) **/
  private serveFunctionsLocally() {
    this.fns.useFunctionsEmulator('http://localhost:4001');
  }
}

const FUNCTION_DEFAULTS = {
  'project-deploymentExport': {},
} as const;

/** List of known function names available for call */
type ICallableFunctionName = keyof typeof FUNCTION_DEFAULTS;

/** Deprecated - use callable functions instead of http requests (or xhr methods for project export) */
/**
import axios, { AxiosRequestConfig } from 'axios';

public callAPI(requestMethod: AxiosRequestConfig['method'], endpoint: string) {
  // TODO - could be moved to interceptor
  const apiBase = 'http://localhost';
  const apiEndpoint = new URL(`${apiBase}/${endpoint}`).toString();
  axios.request({ method: requestMethod });
}
*/
