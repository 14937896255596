import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-recording-guidelines',
  templateUrl: './recording-guidelines.component.html',
  styleUrls: ['./recording-guidelines.component.scss'],
})
export class RecordingGuidelinesComponent {
  @Input() type: 'audio' | 'video' = 'audio';
}
