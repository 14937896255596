import { Component } from '@angular/core';
import { DataService } from 'src/services/data.service';
import { DATA_TEMPLATES } from 'src/data/templates';
import { takeWhile } from 'rxjs/operators';
import { IProject } from 'src/types/types';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';

@Component({
  templateUrl: './admin.page.html',
  styleUrls: ['./admin.page.scss'],
})
export class AdminPage {
  isLoading = false;
  constructor(public dataService: DataService, private dialog: MatDialog) { }

  async promptLoadTemplate() {
    const loadTemplateDialog = this.dialog.open(AdminTemplatePickerComponent);
    loadTemplateDialog.afterClosed().subscribe(async (data) => {
      if (data) {
        const [template_id, wipeProjectData] = data
        return this.loadStringFromTemplate(template_id, wipeProjectData)
      }
    });
  }

  private async loadStringFromTemplate(template_id: string, wipeProjectData = false) {
    // as it might take a time for all the background functions to process just loading state
    // to prevent duplicate submission
    this.isLoading = true;
    const entries = DATA_TEMPLATES[template_id];
    if (entries) {
      if (wipeProjectData) {
        await this.dataService.wipeActiveProject();
        // wait for backend triggers to also complete that delete mediaSummary entries on doc delete
        const obs = this.dataService.activeProject$
          .pipe(takeWhile<IProject>((p) => Object.keys(p.mediaSummary).length > 0))
        await lastValueFrom(obs)
      }
      // TODO - better data prep methods available in import page. Should merge and refactor
      await this.dataService.uploadDBDocuments(entries);
    } else {
      console.error('template not found', template_id, 'available:', Object.keys(DATA_TEMPLATES));
      throw new Error('no template exists for ' + template_id);
    }
  }
}

@Component({
  template: `
<h2 mat-dialog-title>Load Template Data</h2>
<mat-dialog-content style="padding:8px 24px">
  <mat-radio-group [(ngModel)]="selectedTemplateId" aria-label="Select a template" style="display:flex; flex-direction:column;">
    <mat-radio-button *ngFor="let templateId of templateIds" [value]="templateId"  style="margin:5px 10px">
    <span>{{templateId}}</span>
  </mat-radio-button>
  </mat-radio-group>

</mat-dialog-content>
<mat-checkbox [(ngModel)]="selectedWipeProjectData">Clear existing data</mat-checkbox>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button [mat-dialog-close]="[selectedTemplateId,selectedWipeProjectData]">Load</button>
</mat-dialog-actions>
` })

export class AdminTemplatePickerComponent {
  selectedWipeProjectData = false
  selectedTemplateId: string
  templateIds = Object.keys(DATA_TEMPLATES)
}
