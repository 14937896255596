import packageJSON from '../../package.json';
import strings from './strings';

export interface IEnvironment {
  firebaseConfig: any;
  functionsEndpoint: string;
  /** Specify if a proxy is used to access api endpoint (stored in firebase hosting rewrite as project-api) */
  projectAPIProxy?: boolean;
  version: string;
  strings: typeof strings;
  DEFAULT_PROJECT?: string;
  DEFAULT_SUBPROJECT?: string;
  production: boolean;
  home: {
    banner: string;
  };
}
const ENV_DEFAULT: IEnvironment = {
  home: {
    banner: 'assets/deployments/staging/banner.png',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyA291sLguRhrFelfZ-bUnto9d9AtZkd1WY',
    authDomain: 'idems-media-recorder-staging-1.firebaseapp.com',
    databaseURL: 'https://idems-media-recorder-staging-1.firebaseio.com',
    projectId: 'idems-media-recorder-staging-1',
    storageBucket: 'idems-media-recorder-staging-1.appspot.com',
    messagingSenderId: '713598845181',
    appId: '1:713598845181:web:895453fa95516b0e353e94',
    measurementId: 'G-EV0FXNFCP4',
  },
  projectAPIProxy: false,
  functionsEndpoint: 'https://us-central1-idems-media-recorder-staging-1.cloudfunctions.net',
  version: packageJSON.version,
  strings,
  production: false,
  DEFAULT_PROJECT: 'default',
  DEFAULT_SUBPROJECT: 'default',
};

// Try to assign production mode based on host (will fail if running via node scripts)
try {
  ENV_DEFAULT.production = location.hostname !== 'localhost';
} catch (error) {}

export { ENV_DEFAULT };
export const environment = ENV_DEFAULT;
