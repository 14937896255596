<div class="page">
  <div class="flex-container" style="margin-bottom: 1em">
    <app-back-button buttonText="All Projects"></app-back-button>
    <button
      style="margin-left: auto"
      mat-button
      routerLink="/admin"
      [disabled]="!dataService.userIsProjectAdmin"
      [title]="dataService.userIsProjectAdmin ? 'Import Data' : 'Only project admins can manage project'"
    >
      <mat-icon>settings</mat-icon>Manage Project
    </button>
  </div>
  <div data-cy="project-summary-container" class="project-summary-container">
    <app-project-card
      [project]="dataService.activeProject"
      containerClass="no-hover"
      variant="no-hover"
    ></app-project-card>
  </div>
  <app-recording-list
    [mediaSummary]="(dataService.activeProject$ | async).mediaSummary"
    [lockedDocs]="dataService.lockedDocs$ | async"
  ></app-recording-list>
</div>
