import { Component, Input } from '@angular/core';
import { DataService } from 'src/services/data.service';
import { IProject } from 'src/types/types';

@Component({
  selector: 'app-project-progress',
  templateUrl: './project-progress.component.html',
  styleUrls: ['./project-progress.component.scss'],
})
export class ProjectProgressComponent {
  total = 0;
  progress = 0;
  recorded = 0;

  @Input() set project(project: IProject) {
    this._processMediaSummary(project.mediaSummary);
  }

  constructor(public dataService: DataService) {}

  /**
   * Media summaries are stored as lists of {id:downloadUrl} pairs
   * Extract simple count of total strings and number of recorded
   */
  private _processMediaSummary(summary: IProject['mediaSummary'] = {}) {
    {
      const values = Object.values(summary);
      this.total = values.length;
      this.recorded = values.filter((e) => (e.media ? true : false)).length;
      this.progress = Math.ceil((this.recorded / this.total) * 100);
    }
  }
}
