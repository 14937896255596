import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { AuthService } from 'src/services/auth.service';
import { DataService } from 'src/services/data.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Prevent user accessing page unless logged in
 * Default redirect to home page
 */
export class UserLoggedInGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate() {
    if (this.authService.user) {
      return true;
    } else {
      // If there's no user sign in anonymously, passing back an observable
      // that resolves true when the user is signed in
      const waitForLogin: Observable<boolean> = this.authService.user$.pipe(
        map((v) => (v && v.uid ? true : false)),
        first((loggedIn) => loggedIn === true)
      );
      // In case of direct navigation wait to see if firebase still signs in to existing account
      setTimeout(() => {
        if (!this.authService.user) {
          this.authService.signInAnonymously().catch((err) => console.error(err));
        }
      }, 1000);
      return waitForLogin;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
/**
 * Most pages should only be accessed if there is an active project currently
 */
export class ActiveProjectGuard implements CanActivate {
  constructor(private dataService: DataService, private router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.dataService.activeProject$.value) {
      return true;
    } else {
      this.router.navigate(['/']);
    }
  }
}

@Injectable({
  providedIn: 'root',
})
/**
 * Allow access to page only if active project has logged in user listed as admin
 */
export class ProjectAdminGuard implements CanActivate {
  constructor(private dataService: DataService, private router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.dataService.userIsProjectAdmin) {
      return true;
    } else {
      this.router.navigate(['/']);
    }
  }
}
