<app-main-header></app-main-header>
<div [@routeAnimations]="prepareRoute(outlet)" class="page-router-container">
  <router-outlet #outlet="outlet"></router-outlet>
</div>

<!-- Sidenav layout (not currently implemented) -->

<!-- <div class="main-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="toolbar">
    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <h1 style="flex: 1; cursor: pointer" routerLink="/">
      {{ strings.HOME_PAGE_TITLE }}
    </h1>
    <span class="metadata">v{{ APP_VERSION }}</span>
  </mat-toolbar>

  <mat-sidenav-container
    class="sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 56 : 0"
  >
    <mat-sidenav
      #snav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56"
      [opened]="!mobileQuery.matches"
    >
      <mat-nav-list>
        <a mat-list-item routerLink="/">Home</a>
        <a mat-list-item routerLink="/admin">Admin</a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content
      ><div
        [@routeAnimations]="prepareRoute(outlet)"
        class="page-router-container"
      >
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div> -->
