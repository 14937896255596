<div class="page">
  <div style="display: flex; align-items: center; margin-bottom: 1em">
    <app-back-button></app-back-button>
    <h2 style="margin: 0">
      <span *ngIf="(dataService.userLockedDocuments$ | async)[0] as doc"
        >{{doc.title || 'Recording'}}</span
      >
    </h2>
  </div>

  <div *ngFor="let doc of (dataService.userLockedDocuments$ | async)" class="recording-container">
    <app-recorder
      *ngIf="!doc.media"
      [uploadOptions]="getUploadOptions(doc)"
      [recordingText]="doc.text"
    ></app-recorder>
    <mat-card *ngIf="doc.media">
      <h3>Media Uploaded</h3>
      <audio controls="" autoplay name="media" *ngIf="doc.type==='audio'">
        <source [src]="doc.media.downloadUrl" [type]="doc.media.contentType" />
      </audio>
      <video style="width: 100%" controls="" autoplay name="media" *ngIf="doc.type==='video'">
        <source [src]="doc.media.downloadUrl" [type]="doc.media.contentType" />
      </video>
      <div class="button-group" style="margin: 1em 0">
        <button
          *ngIf="doc.media"
          mat-button
          (click)="deleteRecording(doc)"
          color="warn"
          [disabled]="isDeleting"
        >
          <mat-icon>delete</mat-icon>Delete
        </button>
        <button mat-stroked-button routerLink="/record"><mat-icon>done</mat-icon>Finished</button>
      </div>
    </mat-card>
    <div class="metadata">Media Locked Until: {{doc._lockExpires.toDate() | date:'h:mm a'}}</div>
  </div>
</div>
