<h2>Edit Entry</h2>
<form [formGroup]="entryForm">
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>ID</mat-label>
    <input matInput formControlName="id" />
  </mat-form-field>
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>Title</mat-label>
    <input matInput formControlName="title" />
  </mat-form-field>
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>Text</mat-label>
    <textarea matInput formControlName="text"></textarea>
  </mat-form-field>

  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="save()" [disabled]="isSaving">Save</button>
  </div>

  <ng-template #confirmDelete>
    <h2 matDialogTitle>Delete Entry</h2>
    <mat-dialog-content>
      <p>Are you sure you want to delete this entry?</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button matDialogClose color="primary">Cancel</button>
      <button mat-button [matDialogClose]="true" color="primary">Delete Entry</button>
    </mat-dialog-actions>
  </ng-template>
</form>
