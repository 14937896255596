import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COUNTRIES_LIST } from 'src/data/lists/countries';
import { DataService } from 'src/services/data.service';
import { IProject } from 'src/types/types';

@Component({
  selector: 'app-project-filter',
  template: `<mat-form-field appearance="outline">
      <mat-label>Filter by Country</mat-label>
      <mat-select
        #countrySelect
        (selectionChange)="filterProjects(countrySelect.value, subprojectSelect.value)"
      >
        <mat-option [value]="null">All</mat-option>
        <mat-option *ngFor="let country of filterCountries" [value]="country.value">
          {{ country.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" style="margin-left:10px">
      <mat-label>Filter by Subproject</mat-label>
      <mat-select
        #subprojectSelect
        (selectionChange)="filterProjects(countrySelect.value, subprojectSelect.value)"
      >
        <mat-option [value]="null">All</mat-option>
        <mat-option *ngFor="let subproject of filterSubprojects" [value]="subproject">
          {{ subproject }}
        </mat-option>
      </mat-select>
    </mat-form-field> `,
})
export class ProjectFilterComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject();
  filterCountries: { label: string; value: string }[] = [];
  filterSubprojects: string[] = [];
  _projects: IProject[];
  @Input() set projects(projects: IProject[]) {
    this._projects = projects;
    this.generateProjectCountryFilterList(projects);
    this.generateSubprojectFilterList(projects);
  }
  @Output() projectsFiltered = new EventEmitter<IProject[]>();

  constructor(public dataService: DataService) {}

  ngOnInit() {
    this.dataService.publicProjects$.pipe(takeUntil(this.destroyed$)).subscribe((projects) => {
      this.generateProjectCountryFilterList(projects);
      this.filterProjectsByCountry(null);
    });
  }
  ngOnDestroy() {
    this.destroyed$.next(true);
  }
  filterProjectsByCountry(country: string) {
    const filtered = country ? this._projects.filter((p) => p.country === country) : this._projects;
    this.projectsFiltered.next(filtered);
  }
  filterProjects(country: string, subproject: string) {
    let filtered = this._projects;
    if (country) {
      filtered = filtered.filter((p) => p.country === country);
    }
    if (subproject) {
      filtered = filtered.filter((p) => p.subproject === subproject);
    }
    this.projectsFiltered.next(filtered);
  }

  generateProjectCountryFilterList(projects: IProject[]) {
    this.filterCountries = [...new Set(projects.map((p) => p.country))]
      .map((code) => ({
        value: code,
        label: COUNTRIES_LIST[code].name,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  }

  generateSubprojectFilterList(projects: IProject[]) {
    const subprojects = {};
    projects.forEach((p) => (subprojects[p.subproject] = true));
    this.filterSubprojects = Object.keys(subprojects);
  }
}
